<template>
  <div>
    <HeaderPanel
      title="Inventory Transfer"
      :hasDropdown="false"
      :filter="filter"
      placeholder="Search Name"
      @sidebar="openFilter"
      @search="getList"
      routerPath="/inventory-transfer/detail/0"
    >
    </HeaderPanel>

    <!-- </ul> -->

    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <Pagination
        @handleChangeTake="handleChangeTake"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </div>

    <!-- <router-view></router-view> -->
    <!-- <FilterComponent ref="filter" :filter="filter" @submitFilter="getList" /> -->
  </div>
</template>
<script>
// import FilterComponent from "./SidebarFilter";

export default {
  name: "TransactionPending",
  components: {
    // FilterComponent,
    // HeaderPanel,
  },
  data() {
    return {
      filter: {
        search: "",
        transaction_date_from: "",
        transaction_date_to: "",
        branch_filter: [], // list int
        source_id: [], // list int
        channel_id: [], // list int
        status_id: [], // list int (transaction status)
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      fields: [
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Code",
          key: "code",
        },
        {
          label: "Description",
          key: "description",
        },

        {
          label: "Create Date",
          key: "created_time",
        },

        {
          label: "Status",
          key: "status",
        },
        {
          label: "Action",
          key: "action",
        },
      ],
      items: [],
      data: {
        total: 0,
        total_success: 0,
        total_pending: 0,
      },
    };
  },
  created() {
    // this.getList();
  },

  methods: {
    async getList() {
      this.isBusy = true;
      const response = await this.axios.post(
        `/transaction/transactionpending/list`,
        this.filter
      );

      this.rows = response.data.detail.total_count;
      this.items = response.data.detail.transaction_list;
      this.isBusy = false;
    },

    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
    openFilter() {
      // this.$refs.filter.show();
    },
  },
};
</script>
<style lang="scss">
.banner-image {
  max-width: 50px;
  height: auto;
  width: 100%;
  aspect-ratio: 1;
}
</style>
